/*
* All roles and rights used in the code must be defined here.
*
* DO NOT EDIT THIS FILE!
* This file is generated by the authorisation used in PHP and will be
* overwritten by maintenance process.
*/

export const ALLOWED_ROUTES = ['login', 'handle_login', 'forgot_password']; // You do not have to be logged in for this
export const ALLOWED_ROLES = ['admin']; // Super role TDD: Is this role necessary?
export const REDIRECT_URL = 'REDIRECT_URL';
export const ALLOWED_PATHS = ['login', 'handle_login', 'public_wbt', 'forgot_password', 'apply']; // You do not have to be logged in for this
// eslint-disable-next-line import/prefer-default-export
export const UserAuthorisation = {
// User roles
  ROLE__ACADEMIC_MANAGER: 'ACADEMIC_MANAGER', // Academic Manager:
  ROLE__ADMIN: 'ADMIN', // Admin:Admin for JWL
  ROLE__COORDINATOR: 'COORDINATOR', // Coordinator:
  ROLE__FACILITATOR: 'FACILITATOR', // Facilitator:
  ROLE__FACULTY: 'FACULTY', // Faculty:
  ROLE__LEAD_COORDINATOR: 'LEAD_COORDINATOR', // Lead coordinator:
  ROLE__LEAD_FACILITATOR: 'LEAD_FACILITATOR', // Lead facilitator:
  ROLE__LEAD_FACULTY: 'LEAD_FACULTY', // Lead faculty:
  ROLE__OPERATIONAL_MANAGER: 'OPERATIONAL_MANAGER', // Operational Manager:
  ROLE__STUDENT: 'STUDENT', // Student:
  ROLE__SUPER_ADMIN: 'SUPER_ADMIN', // Super admin:Super admin for JWL
  ROLE__TEACHING_ASSISTANT: 'TEACHING_ASSISTANT', // Teaching Assistant:
  ROLE__UNIVERSITY_REPRESENTATIVE: 'UNIVERSITY_REPRESENTATIVE', // University representative:
  ROLE__VIEW_ONLY_USER: 'VIEW_ONLY_USER', // View only user:
  // User rights
  RIGHT__ACTIVE_COURSES__DISPLAY: 'ACTIVE_COURSES__DISPLAY', // Display courses tab:
  RIGHT__ADDITIONAL_COURSES__DISPLAY: 'ADDITIONAL_COURSES__DISPLAY', // Display additional courses:
  RIGHT__ANNOUNCEMENT__DISPLAY: 'ANNOUNCEMENT__DISPLAY', // Display announcement:
  RIGHT__ANNOUNCEMENT__EDIT: 'ANNOUNCEMENT__EDIT', // Edit announcement:
  RIGHT__APPLICANT__EXPORT: 'APPLICANT__EXPORT', // Export applicant(s):
  RIGHT__AUTHORISATION__DISPLAY: 'AUTHORISATION__DISPLAY', // Display authorisation:
  RIGHT__BA_APPLICANT_ADD_JWL_ID_CREATE: 'BA_APPLICANT_ADD_JWL_ID_CREATE', // Add an existing User to cohort by JWL-ID:
  RIGHT__BA_COHORT_MANAGEMENT_APPLICANT__EXPORT: 'BA_COHORT_MANAGEMENT_APPLICANT__EXPORT', // Generate applicant exports in general and per cohort:
  RIGHT__BA_COHORT_MANAGEMENT_COHORT__CREATE: 'BA_COHORT_MANAGEMENT_COHORT__CREATE', // Create new BA Application Cohort :
  RIGHT__BA_COHORT_MANAGEMENT_STUDENT_COURSE_CORRECTION__EDIT: 'BA_COHORT_MANAGEMENT_STUDENT_COURSE_CORRECTION__EDIT', // Allows to manually do an administrative error correction for a student course in BA management:
  RIGHT__BA_COHORT_MANAGEMENT_STUDENT_COURSE_DROPDELETE__EDIT: 'BA_COHORT_MANAGEMENT_STUDENT_COURSE_DROPDELETE__EDIT', // Allows to manually drop-delete a student course in BA management:
  RIGHT__BA_COHORT_MANAGEMENT_STUDENT_COURSE_FAIL__EDIT: 'BA_COHORT_MANAGEMENT_STUDENT_COURSE_FAIL__EDIT', // Allows to manually fail a student course in BA management:
  RIGHT__BA_COHORT_MANAGEMENT_STUDENT_COURSE_WITHDRAW__EDIT: 'BA_COHORT_MANAGEMENT_STUDENT_COURSE_WITHDRAW__EDIT', // Allows to manually withdraw a student course in BA management:
  RIGHT__BA_COHORT_MANAGEMENT_STUDENT_IMPORT__EDIT: 'BA_COHORT_MANAGEMENT_STUDENT_IMPORT__EDIT', // Import Students form Admission Portal:
  RIGHT__BA_COHORT_MANAGEMENT_STUDENT_PROGRAMME_STATUS__EDIT: 'BA_COHORT_MANAGEMENT_STUDENT_PROGRAMME_STATUS__EDIT', // Edit students programme Status :
  RIGHT__BA_COHORT_MANAGEMENT__DISPLAY: 'BA_COHORT_MANAGEMENT__DISPLAY', // Show programme Cohorts and applicants:
  RIGHT__BA_COHORT_MANAGEMENT__EDIT: 'BA_COHORT_MANAGEMENT__EDIT', // Edit programme Cohort CLCs, and applicant Program Status change actions, add applicants by ID:
  RIGHT__BA_STUDENT_MANAGEMENT_CLASSROOM__DISPLAY: 'BA_STUDENT_MANAGEMENT_CLASSROOM__DISPLAY', // View classroom management in BA student management:
  RIGHT__BA_STUDENT_MANAGEMENT_CURRENT_TERM__DISPLAY: 'BA_STUDENT_MANAGEMENT_CURRENT_TERM__DISPLAY', // Current Term Display:
  RIGHT__BA_STUDENT_MANAGEMENT_CURRENT_TERM__EDIT: 'BA_STUDENT_MANAGEMENT_CURRENT_TERM__EDIT', // Current Term Edit:
  RIGHT__BA_STUDENT_MANAGEMENT_EXTRA_COURSES_SCHEDULE__DISPLAY: 'BA_STUDENT_MANAGEMENT_EXTRA_COURSES_SCHEDULE__DISPLAY', // Show planed extra courses (BA SIS):
  RIGHT__BA_STUDENT_MANAGEMENT_EXTRA_COURSES_SCHEDULE__EDIT: 'BA_STUDENT_MANAGEMENT_EXTRA_COURSES_SCHEDULE__EDIT', // Edit extra courses (BA SIS):
  RIGHT__BA_STUDENT_MANAGEMENT_INTERNSHIP__DISPLAY: 'BA_STUDENT_MANAGEMENT_INTERNSHIP__DISPLAY', // Internship terms display:
  RIGHT__BA_STUDENT_MANAGEMENT_NEXT_TERM__DISPLAY: 'BA_STUDENT_MANAGEMENT_NEXT_TERM__DISPLAY', // Display next term:
  RIGHT__BA_STUDENT_MANAGEMENT_NEXT_TERM__EDIT: 'BA_STUDENT_MANAGEMENT_NEXT_TERM__EDIT', // Edit next term:
  RIGHT__BA_STUDENT_MANAGEMENT_PAST_CLASSROOM__DISPLAY: 'BA_STUDENT_MANAGEMENT_PAST_CLASSROOM__DISPLAY', // Past classrooms display:
  RIGHT__BA_STUDENT_MANAGEMENT_PAST_CLASSROOM__EDIT: 'BA_STUDENT_MANAGEMENT_PAST_CLASSROOM__EDIT', // Past classrooms edit:
  RIGHT__BA_STUDENT_MANAGEMENT_PAST_TERM__DISPLAY: 'BA_STUDENT_MANAGEMENT_PAST_TERM__DISPLAY', // Display past term:
  RIGHT__BA_STUDENT_MANAGEMENT_PAST_TERM__EDIT: 'BA_STUDENT_MANAGEMENT_PAST_TERM__EDIT', // Edit past term:
  RIGHT__BA_STUDENT_MANAGEMENT_TRANSFER_IN_FROM_INTERNAL__DISPLAY: 'BA_STUDENT_MANAGEMENT_TRANSFER_IN_FROM_INTERNAL__DISPLAY', // Display  transfer in from internal :
  RIGHT__BA_STUDENT_MANAGEMENT_TRANSFER_IN__EDIT: 'BA_STUDENT_MANAGEMENT_TRANSFER_IN__EDIT', // Edit transfer in:
  RIGHT__BA_STUDENT_MANAGEMENT_TRANSFER_OUT__DISPLAY: 'BA_STUDENT_MANAGEMENT_TRANSFER_OUT__DISPLAY', // Transfer-out page in BA management:
  RIGHT__BA_STUDENT_MANAGEMENT_TRANSFER_OUT__EDIT: 'BA_STUDENT_MANAGEMENT_TRANSFER_OUT__EDIT', // Transfer-out actions in BA management:
  RIGHT__BA_STUDENT_MANAGEMENT__DISPLAY: 'BA_STUDENT_MANAGEMENT__DISPLAY', // Display student management tab:
  RIGHT__BA_STUDENT_STATUS__EDIT: 'BA_STUDENT_STATUS__EDIT', // Edit BA courses student status:
  RIGHT__BA_TERM_MANAGEMENT_ACADEMIC_CALENDAR__DISPLAY: 'BA_TERM_MANAGEMENT_ACADEMIC_CALENDAR__DISPLAY', // Show academic calendar / Recommanded courses:
  RIGHT__BA_TERM_MANAGEMENT__EDIT: 'BA_TERM_MANAGEMENT__EDIT', // Term management Edit:
  RIGHT__CERTIFICATE__CREATE: 'CERTIFICATE__CREATE', // Create and download a certificate:
  RIGHT__CLASSROOM_CONTACT_STAFF__EDIT: 'CLASSROOM_CONTACT_STAFF__EDIT', // Contact the staff of classroom:
  RIGHT__CLASSROOM_CONTACT_STUDENT__EDIT: 'CLASSROOM_CONTACT_STUDENT__EDIT', // Contact students of classroom:
  RIGHT__CLASSROOM_FACILITATOR__EDIT: 'CLASSROOM_FACILITATOR__EDIT', // Edit facilitator of classroom:
  RIGHT__CLASSROOM_GLOBAL__DISPLAY: 'CLASSROOM_GLOBAL__DISPLAY', // Display classrooms in gradebook of type global:
  RIGHT__CLASSROOM_LISTS_NEXT_TERM__EXPORT: 'CLASSROOM_LISTS_NEXT_TERM__EXPORT', // Export list of next term classroom members:
  RIGHT__CLASSROOM_LISTS_RUNNING_TERM__EXPORT: 'CLASSROOM_LISTS_RUNNING_TERM__EXPORT', // Export list of running term classroom members:
  RIGHT__CLASSROOM_LOCAL__DISPLAY: 'CLASSROOM_LOCAL__DISPLAY', // Display classrooms in gradebook of type local:
  RIGHT__CLASSROOM_MANAGEMENT_CLASSROOM__EDIT: 'CLASSROOM_MANAGEMENT_CLASSROOM__EDIT', // Allow student drag & drop, rename & update classrom:
  RIGHT__CLASSROOM_MANAGEMENT_FACILITATOR__EDIT: 'CLASSROOM_MANAGEMENT_FACILITATOR__EDIT', // Assign faciliators to onsite classrooms:
  RIGHT__CLASSROOM_MANAGEMENT_FACULTY__EDIT: 'CLASSROOM_MANAGEMENT_FACULTY__EDIT', // Assign faculty to online classrooms:
  RIGHT__CLASSROOM_NAME__EDIT: 'CLASSROOM_NAME__EDIT', // Edit name of classroom:
  RIGHT__CLASSROOM_STUDENT__CREATE: 'CLASSROOM_STUDENT__CREATE', // Create (add) a student to classroom:
  RIGHT__CLASSROOM_STUDENT__EDIT: 'CLASSROOM_STUDENT__EDIT', // Edit students in classroom:
  RIGHT__CLASSROOM__DISPLAY: 'CLASSROOM__DISPLAY', // Display classroom:
  RIGHT__CLASSROOM__EXPORT: 'CLASSROOM__EXPORT', // Export classroom members (GEL only):
  RIGHT__CLC_DETAIL__DISPLAY: 'CLC_DETAIL__DISPLAY', // Display CLC detail:
  RIGHT__CLC_REPORTING_STATUS__EDIT: 'CLC_REPORTING_STATUS__EDIT', // Edit checkbox to exclude CLC from reporting:
  RIGHT__CLC__CREATE: 'CLC__CREATE', // Create CLC:
  RIGHT__CLC__DELETE: 'CLC__DELETE', // Delete CLC:
  RIGHT__CLC__DISPLAY: 'CLC__DISPLAY', // Display CLC:
  RIGHT__CLC__EDIT: 'CLC__EDIT', // Edit CLC:
  RIGHT__COORDINATOR_CLC__EDIT: 'COORDINATOR_CLC__EDIT', // Edit coordinator to CLC relation:
  RIGHT__COORDINATOR__CREATE: 'COORDINATOR__CREATE', // Create coordinator:
  RIGHT__COORDINATOR__DELETE: 'COORDINATOR__DELETE', // Delete coordinator:
  RIGHT__COORDINATOR__DISPLAY: 'COORDINATOR__DISPLAY', // Display coordinator:
  RIGHT__COORDINATOR__EDIT: 'COORDINATOR__EDIT', // Edit coordinator:
  RIGHT__COURSE_ACCEPT_OR_DECLINE_STUDENTS__EDIT: 'COURSE_ACCEPT_OR_DECLINE_STUDENTS__EDIT', // Edit course accept or decline:
  RIGHT__COURSE_COORDINATOR_DOCUMENTS_DISPLAY: 'COURSE_COORDINATOR_DOCUMENTS_DISPLAY', // See coordinator documents in courses:
  RIGHT__COURSE_ONLINE_DOCUMENTS_DISPLAY: 'COURSE_ONLINE_DOCUMENTS_DISPLAY', // See online documents in courses:
  RIGHT__COURSE_ONSITE_DOCUMENTS_DISPLAY: 'COURSE_ONSITE_DOCUMENTS_DISPLAY', // See onsite documents in courses:
  RIGHT__COURSE_PREVIEW__DISPLAY: 'COURSE_PREVIEW__DISPLAY', // Display course preview:
  RIGHT__COURSE_TASK_ONLINE__DISPLAY: 'COURSE_TASK_ONLINE__DISPLAY', // See tasks with responsibility online faculty:
  RIGHT__COURSE_TASK_ONSITE__DISPLAY: 'COURSE_TASK_ONSITE__DISPLAY', // See tasks with responsibility onsite facilitator:
  RIGHT__COURSE_TERM_SWITCH__DISPLAY: 'COURSE_TERM_SWITCH__DISPLAY', // See the term switch in LMS class tab:
  RIGHT__DATA_CLEAN_UP__DISPLAY: 'DATA_CLEAN_UP__DISPLAY', // Display data cleanup:
  RIGHT__DATA_CLEAN_UP__EDIT: 'DATA_CLEAN_UP__EDIT', // Create data cleanup (means data modification on db):
  RIGHT__FACILITATOR_CLC__EDIT: 'FACILITATOR_CLC__EDIT', // Edit facilitator in CLC:
  RIGHT__FACILITATOR_INACTIVE__EDIT: 'FACILITATOR_INACTIVE__EDIT', // Edit facilitator active / inactive:
  RIGHT__FACILITATOR_ONLINE_FACULTY__CREATE: 'FACILITATOR_ONLINE_FACULTY__CREATE', // Create onsite faculty:
  RIGHT__FACILITATOR_ONSITE_FACILITATOR__CREATE: 'FACILITATOR_ONSITE_FACILITATOR__CREATE', // Create onsite facilitator:
  RIGHT__FACILITATOR_QUALIFICATION__EDIT: 'FACILITATOR_QUALIFICATION__EDIT', // Edit qualification of facilitator:
  RIGHT__FACILITATOR__CREATE: 'FACILITATOR__CREATE', // Create facilitator:
  RIGHT__FACILITATOR__DELETE: 'FACILITATOR__DELETE', // Delete facilitator:
  RIGHT__FACILITATOR__DISPLAY: 'FACILITATOR__DISPLAY', // Display facilitator:
  RIGHT__FACILITATOR__EDIT: 'FACILITATOR__EDIT', // Edit facilitator:
  RIGHT__FACULTY_INACTIVE__EDIT: 'FACULTY_INACTIVE__EDIT', // Edit facilitator active / inactive:
  RIGHT__GEL_APPLICANTS__DISPLAY: 'GEL_APPLICANTS__DISPLAY', // Display list of GEL Applicants:
  RIGHT__GEL_APPLICANT_ADD_JWL_ID_CREATE: 'GEL_APPLICANT_ADD_JWL_ID_CREATE', // Add an existing User to GEL by JWL-ID:
  RIGHT__GEL_APPLICANT__EDIT: 'GEL_APPLICANT__EDIT', // Edit GEL applicant :
  RIGHT__GEL_CERTIFICATE_SIGNEE__EDIT: 'GEL_CERTIFICATE_SIGNEE__EDIT', // Edit GEL Certificate Signee information:
  RIGHT__GEL_CERTIFICATE__CREATE: 'GEL_CERTIFICATE__CREATE', // Create GEL Certificates:
  RIGHT__GEL_CLASSROOM_FACILITATOR__EDIT: 'GEL_CLASSROOM_FACILITATOR__EDIT', // Assign Facilitators to classroom:
  RIGHT__GEL_CLASSROOM__CREATE: 'GEL_CLASSROOM__CREATE', // Create a new GEL classroom:
  RIGHT__GEL_CLASSROOM__EDIT: 'GEL_CLASSROOM__EDIT', // Move students in GEL classrooms:
  RIGHT__GEL_CLASSROOM__EXPORT: 'GEL_CLASSROOM__EXPORT', // Export the GEL classroom student list:
  RIGHT__GEL_CLC__CREATE: 'GEL_CLC__CREATE', // Add an existing CLC to the GEL programme:
  RIGHT__GEL_EOL__EDIT: 'GEL_EOL__EDIT', // Edit EOL results:
  RIGHT__GEL_PENDING_APPLICANTS__DISPLAY: 'GEL_PENDING_APPLICANTS__DISPLAY', // Display list of pending GEL Applicants:
  RIGHT__GEL_PENDING_APPLICANTS__EDIT: 'GEL_PENDING_APPLICANTS__EDIT', // Edit pending GEL applicants (accept/decline) :
  RIGHT__GEL_PENDING_APPLICANT_PASSWORD_RESET__EDIT: 'GEL_PENDING_APPLICANT_PASSWORD_RESET__EDIT', // Generate password reset for pending applicants:
  RIGHT__GEL_PREPENDING_APPLICANTS__DISPLAY: 'GEL_PREPENDING_APPLICANTS__DISPLAY', // Display list of prepending GEL Applicants:
  RIGHT__GEL_STUDENT_ENTRY__DELETE: 'GEL_STUDENT_ENTRY__DELETE', // Delete entries from GEL status records:
  RIGHT__GEL_STUDENT_EOL_ACTIVE__EDIT: 'GEL_STUDENT_EOL_ACTIVE__EDIT', // Edit EOL results:
  RIGHT__GEL_STUDENT_PROGRAMME_STATUS_WITHDRAW__EDIT: 'GEL_STUDENT_PROGRAMME_STATUS_WITHDRAW__EDIT', // Change programme status from withdraw:
  RIGHT__GEL_STUDENT__MOVE: 'GEL_STUDENT__MOVE', // Move GEL Applicant:
  RIGHT__GEL_STUDY_HISTORY__EDIT: 'GEL_STUDY_HISTORY__EDIT', // Edit GEL applicant history :
  RIGHT__GEL_TERM_MANAGEMENT__DISPLAY: 'GEL_TERM_MANAGEMENT__DISPLAY', // Display the GEL term management:
  RIGHT__GEL_TERM_MANAGEMENT__EDIT: 'GEL_TERM_MANAGEMENT__EDIT', // Edit the GEL term management:
  RIGHT__GEL_TERM_START_DATE__EDIT: 'GEL_TERM_START_DATE__EDIT', // Edit the GEL start date of a GEL classroom :
  RIGHT__GEL_TERM__EDIT: 'GEL_TERM__EDIT', // Edit the GEL term:
  RIGHT__GEL_VALIDATE_PENDING_MINOR_APPLICANTS__EDIT: 'GEL_VALIDATE_PENDING_MINOR_APPLICANTS__EDIT', // Accept or decline pending minor applicants :
  RIGHT__GENERAL_LMS__DISPLAY: 'GENERAL_LMS__DISPLAY', // User can access LMS:
  RIGHT__GENERAL_SIS__DISPLAY: 'GENERAL_SIS__DISPLAY', // User can access SIS:
  RIGHT__GENERAL_UNIVERSITY__DISPLAY: 'GENERAL_UNIVERSITY__DISPLAY', // Display all university content:
  RIGHT__GRADE_BOOK_EXPORT_EXTENDED__DISPLAY: 'GRADE_BOOK_EXPORT_EXTENDED__DISPLAY', // Export grade book:
  RIGHT__GRADE_BOOK_FACILITATOR_DETAILS__DISPLAY: 'GRADE_BOOK_FACILITATOR_DETAILS__DISPLAY', // Display facilitator details in the grade book:
  RIGHT__GRADE_BOOK__DISPLAY: 'GRADE_BOOK__DISPLAY', // Display grade book:
  RIGHT__GRADE_BOOK__EXPORT: 'GRADE_BOOK__EXPORT', // Export grade book:
  RIGHT__GRADE_CHANGE_REQUEST__CREATE: 'GRADE_CHANGE_REQUEST__CREATE', // Create a request to change a grade:
  RIGHT__GRADE_CHANGE_REQUEST__DISPLAY: 'GRADE_CHANGE_REQUEST__DISPLAY', // Display the history of requests to change a grade:
  RIGHT__GRADE_FINAL_EXAM__DISPLAY: 'GRADE_FINAL_EXAM__DISPLAY', // Display final exam grade:
  RIGHT__HOME_ACTIVE_COURSES__DISPLAY: 'HOME_ACTIVE_COURSES__DISPLAY', // Display active courses on home:
  RIGHT__HOME_GRADER__DISPLAY: 'HOME_GRADER__DISPLAY', // Display the grader tool on LMS home:
  RIGHT__LEAD_FACILITATOR__CREATE: 'LEAD_FACILITATOR__CREATE', // Create lead facilitator:
  RIGHT__LEAD_FACILITATOR__DISPLAY: 'LEAD_FACILITATOR__DISPLAY', // Display lead facilitator:
  RIGHT__LEAD_FACILITATOR__EDIT: 'LEAD_FACILITATOR__EDIT', // Edit lead facilitator:
  RIGHT__LEAD_FACULTY__CREATE: 'LEAD_FACULTY__CREATE', // Create lead faculty:
  RIGHT__LEAD_FACULTY__DISPLAY: 'LEAD_FACULTY__DISPLAY', // Display lead faculty:
  RIGHT__LEAD_FACULTY__EDIT: 'LEAD_FACULTY__EDIT', // Edit lead faculty:
  RIGHT__PASSWORD_RESET__EDIT: 'PASSWORD_RESET__EDIT', // Edit password reset:
  RIGHT__PC_APPLICANT_ADD_JWL_ID_CREATE: 'PC_APPLICANT_ADD_JWL_ID_CREATE', // Add an existing User to Professional Programme by JWL-ID:
  RIGHT__PC_CLASSROOM_MANAGEMENT__DISPLAY: 'PC_CLASSROOM_MANAGEMENT__DISPLAY', // Display classroom managemnt in Professional Programmes:
  RIGHT__PC_FINISH_PROGRAMME_STUDENT_STATUS__EDIT: 'PC_FINISH_PROGRAMME_STUDENT_STATUS__EDIT', // Edit professional certificate programme applicant status in program finish process:
  RIGHT__PC_STUDENT_STATUS__EDIT: 'PC_STUDENT_STATUS__EDIT', // Edit professional courses student status:
  RIGHT__PREVIEW_QUIZ__DISPLAY: 'PREVIEW_QUIZ__DISPLAY', // See task preview of quiz questions:
  RIGHT__PREVIEW_SEE_ANSWERS__DISPLAY: 'PREVIEW_SEE_ANSWERS__DISPLAY', // See quiz answers in task preview:
  RIGHT__PROGRAMME_ACADEMIC__DISPLAY: 'PROGRAMME_ACADEMIC__DISPLAY', // Display programme academic:
  RIGHT__PROGRAMME_ADD_BREAK__EDIT: 'PROGRAMME_ADD_BREAK__EDIT', // Edit programme add bread:
  RIGHT__PROGRAMME_APPLICANT__CREATE: 'PROGRAMME_APPLICANT__CREATE', // Add an applicant to a programme:
  RIGHT__PROGRAMME_APPLICANT__EXPORT: 'PROGRAMME_APPLICANT__EXPORT', // Export all applicant(s) in a programme:
  RIGHT__PROGRAMME_APPLICANT__IMPORT: 'PROGRAMME_APPLICANT__IMPORT', // Import applicants for a programme:
  RIGHT__PROGRAMME_GEL__DISPLAY: 'PROGRAMME_GEL__DISPLAY', // Display programme GEL:
  RIGHT__PROGRAMME_LINGUA_SKILL_EXCEL__UPLOAD: 'PROGRAMME_LINGUA_SKILL_EXCEL__UPLOAD', // Upload lingua skills in programme:
  RIGHT__PROGRAMME_PROFESSIONAL__DISPLAY: 'PROGRAMME_PROFESSIONAL__DISPLAY', // Display programme professional:
  RIGHT__PROGRAMME_REVIEW_CERTIFICATE_EXPORT__EDIT: 'PROGRAMME_REVIEW_CERTIFICATE_EXPORT__EDIT', // Enables to download the PC certificates:
  RIGHT__PROGRAMME_REVIEW_GRADES_EXPORT__EDIT: 'PROGRAMME_REVIEW_GRADES_EXPORT__EDIT', // Enables to download the PC final grades export:
  RIGHT__PROGRAMME_REVIEW_GRADES_PASS_FAIL__EDIT: 'PROGRAMME_REVIEW_GRADES_PASS_FAIL__EDIT', // Allows to pass or fail students in PC grade review:
  RIGHT__PROGRAMME_REVIEW_GRADES__DISPLAY: 'PROGRAMME_REVIEW_GRADES__DISPLAY', // Display the Grades Review page in professional certificate:
  RIGHT__PROGRAMME__CLOSE: 'PROGRAMME__CLOSE', // Close programme :
  RIGHT__PROGRAMME__CREATE: 'PROGRAMME__CREATE', // Create programme:
  RIGHT__PROGRAMME__DISPLAY: 'PROGRAMME__DISPLAY', // Display all programme content:
  RIGHT__PROGRAMME__EDIT: 'PROGRAMME__EDIT', // Edit programme :
  RIGHT__QUIZ_SESSION_DETAIL__DISPLAY: 'QUIZ_SESSION_DETAIL__DISPLAY', // Display the quiz session detail page, either to administrate or join:
  RIGHT__QUIZ_SESSION_UNRESTRICTED__EDIT: 'QUIZ_SESSION_UNRESTRICTED__EDIT', // Administer quiz session regardless of class assignment:
  RIGHT__QUIZ_SESSION__CREATE: 'QUIZ_SESSION__CREATE', // Create quiz session:
  RIGHT__QUIZ_SESSION__DELETE: 'QUIZ_SESSION__DELETE', // Delete quiz session:
  RIGHT__QUIZ_SESSION__DISPLAY: 'QUIZ_SESSION__DISPLAY', // Display all quiz sessions:
  RIGHT__QUIZ_SESSION__EDIT: 'QUIZ_SESSION__EDIT', // Edit quiz session (accept or kick out students):
  RIGHT__REPORTING__DISPLAY: 'REPORTING__DISPLAY', // Display reporting:
  RIGHT__RIGHT__CREATE: 'RIGHT__CREATE', // Create right:
  RIGHT__RIGHT__DELETE: 'RIGHT__DELETE', // Delete right:
  RIGHT__RIGHT__DISPLAY: 'RIGHT__DISPLAY', // Display right:
  RIGHT__RIGHT__EDIT: 'RIGHT__EDIT', // Edit right:
  RIGHT__ROLE_TO_RIGHT__EXPORT: 'ROLE_TO_RIGHT__EXPORT', // Export role to right:
  RIGHT__ROLE__CREATE: 'ROLE__CREATE', // Create role:
  RIGHT__ROLE__DELETE: 'ROLE__DELETE', // Delete role:
  RIGHT__ROLE__DISPLAY: 'ROLE__DISPLAY', // Display role:
  RIGHT__ROLE__EDIT: 'ROLE__EDIT', // Edit role:
  RIGHT__SIS_PROGRAMME_GEL__DISPLAY: 'SIS_PROGRAMME_GEL__DISPLAY', // Display GEL programme SIS:
  RIGHT__STUDENT_CLC_CHANGE__EDIT: 'STUDENT_CLC_CHANGE__EDIT', // Edit student CLC or enrollment CLC:
  RIGHT__STUDENT_EXCEPT_MINORS__EDIT: 'STUDENT_EXCEPT_MINORS__EDIT', // Edit all students except minors:
  RIGHT__STUDENT_MINORS__EDIT: 'STUDENT_MINORS__EDIT', // Edit all minors students:
  RIGHT__STUDENT_PROFIL_PICTURE_SECOND_UPLOAD__EDIT: 'STUDENT_PROFIL_PICTURE_SECOND_UPLOAD__EDIT', // Upload student profile picture (from Second upload onwards):
  RIGHT__STUDENT_PROGRAMME_STATUS__EDIT: 'STUDENT_PROGRAMME_STATUS__EDIT', // Edit Student Programme Status:
  RIGHT__STUDENT_STUDY_HISTORY__DISPLAY: 'STUDENT_STUDY_HISTORY__DISPLAY', // Display student:
  RIGHT__STUDENT_WITH_ENROLMENT__EDIT: 'STUDENT_WITH_ENROLMENT__EDIT', // Edit student with enrolment:
  RIGHT__STUDENT__ADVANCED_EDIT: 'STUDENT__ADVANCED_EDIT', // Advanced edit student:
  RIGHT__STUDENT__CREATE: 'STUDENT__CREATE', // Create student:
  RIGHT__STUDENT__DISPLAY: 'STUDENT__DISPLAY', // Display student:
  RIGHT__STUDENT__EDIT: 'STUDENT__EDIT', // Edit student:
  RIGHT__STUDENT__WITHDRAW: 'STUDENT__WITHDRAW', // Withdraw student:
  RIGHT__SUBMISSION_CLOSED_BY__DISPLAY: 'SUBMISSION_CLOSED_BY__DISPLAY', // Controls whether the closer of a submission will be displayed:
  RIGHT__SUBMISSION_CLOSE_IN_UNIT_FOR_ONE_STUDENT__EDIT: 'SUBMISSION_CLOSE_IN_UNIT_FOR_ONE_STUDENT__EDIT', // Edit submission in unit for one student:
  RIGHT__SUBMISSION_CLOSE_IN_UNIT__EDIT: 'SUBMISSION_CLOSE_IN_UNIT__EDIT', // Edit submission in unit:
  RIGHT__SUBMISSION_GRADE_PUBLISH__EDIT: 'SUBMISSION_GRADE_PUBLISH__EDIT', // Edit Submission grade publish:
  RIGHT__SUBMISSION_GRADE_SAVE__EDIT: 'SUBMISSION_GRADE_SAVE__EDIT', // Edit submission grade save:
  RIGHT__SUBMISSION_IN_DISCUSSION__CREATE: 'SUBMISSION_IN_DISCUSSION__CREATE', // Create a submission in discussion:
  RIGHT__SUBMISSION_ON_BEHALF__CREATE: 'SUBMISSION_ON_BEHALF__CREATE', // Create a submission on behalf off:
  RIGHT__SUBMISSION_TURNITIN_REPORT_ADMIN__DISPLAY: 'SUBMISSION_TURNITIN_REPORT_ADMIN__DISPLAY', // Allowed to see Turnitin reports in TII admin mode:
  RIGHT__SUBMISSION_TURNITIN_REPORT_INSTRUCTOR__DISPLAY: 'SUBMISSION_TURNITIN_REPORT_INSTRUCTOR__DISPLAY', // Allowed to see Turnitin reports in TII instructor mode:
  RIGHT__SUBMISSION_TURNITIN_REPORT_LEARNER__DISPLAY: 'SUBMISSION_TURNITIN_REPORT_LEARNER__DISPLAY', // Allowed to see Turnitin reports in TII leaner mode:
  RIGHT__SUBMISSION__CREATE: 'SUBMISSION__CREATE', // Create a submission:
  RIGHT__SUBMISSION__DISPLAY: 'SUBMISSION__DISPLAY', // Display submission:
  RIGHT__SUPPORT_SHOW_ENROLLMENTS__DISPLAY: 'SUPPORT_SHOW_ENROLLMENTS__DISPLAY', // Show class enrollments in support ticket:
  RIGHT__SYLLABUS_OF_ALL_COURSES__DISPLAY: 'SYLLABUS_OF_ALL_COURSES__DISPLAY', // Display syllabus of all courses :
  RIGHT__SYLLABUS_OF_ASSIGNED_COURSES__DISPLAY: 'SYLLABUS_OF_ASSIGNED_COURSES__DISPLAY', // Display syllabus of all assigned courses:
  RIGHT__UNIT_CLOSE_FOR_SUBMISSION_FOR_ALL_STUDENTS__EDIT: 'UNIT_CLOSE_FOR_SUBMISSION_FOR_ALL_STUDENTS__EDIT', // Edit close unit for submission for all student:
  RIGHT__UNIT_CLOSE_FOR_SUBMISSION_FOR_ONE_STUDENT__EDIT: 'UNIT_CLOSE_FOR_SUBMISSION_FOR_ONE_STUDENT__EDIT', // Edit close unit for submission for one student:
  RIGHT__UNIT_PUBLISH_FOR_SUBMISSION_FOR_ALL_STUDENTS__EDIT: 'UNIT_PUBLISH_FOR_SUBMISSION_FOR_ALL_STUDENTS__EDIT', // Publish all student submissions in a unit:
  RIGHT__USER__CREATE: 'USER__CREATE', // Create user:
  RIGHT__USER__DELETE: 'USER__DELETE', // Delete user:
  RIGHT__USER__DISPLAY: 'USER__DISPLAY', // Display user:
  RIGHT__USER__EDIT: 'USER__EDIT', // Edit user:
  RIGHT__USER__IMPERSONATE: 'USER__IMPERSONATE', // User can impersonate:
  RIGHT__WBT_ALL__DISPLAY: 'WBT_ALL__DISPLAY', // See any WBT regardless of qualification:
} as const;
export interface UserAuthorisationInterface {
  ROLE__ACADEMIC_MANAGER: string
  ROLE__ADMIN: string
  ROLE__COORDINATOR: string
  ROLE__FACILITATOR: string
  ROLE__FACULTY: string
  ROLE__LEAD_COORDINATOR: string
  ROLE__LEAD_FACILITATOR: string
  ROLE__LEAD_FACULTY: string
  ROLE__OPERATIONAL_MANAGER: string
  ROLE__STUDENT: string
  ROLE__SUPER_ADMIN: string
  ROLE__TEACHING_ASSISTANT: string
  ROLE__UNIVERSITY_REPRESENTATIVE: string
  ROLE__VIEW_ONLY_USER: string
  RIGHT__ACTIVE_COURSES__DISPLAY: string
  RIGHT__ADDITIONAL_COURSES__DISPLAY: string
  RIGHT__ANNOUNCEMENT__DISPLAY: string
  RIGHT__ANNOUNCEMENT__EDIT: string
  RIGHT__APPLICANT__EXPORT: string
  RIGHT__AUTHORISATION__DISPLAY: string
  RIGHT__BA_APPLICANT_ADD_JWL_ID_CREATE: string
  RIGHT__BA_COHORT_MANAGEMENT_APPLICANT__EXPORT: string
  RIGHT__BA_COHORT_MANAGEMENT_COHORT__CREATE: string
  RIGHT__BA_COHORT_MANAGEMENT_STUDENT_COURSE_CORRECTION__EDIT: string
  RIGHT__BA_COHORT_MANAGEMENT_STUDENT_COURSE_DROPDELETE__EDIT: string
  RIGHT__BA_COHORT_MANAGEMENT_STUDENT_COURSE_FAIL__EDIT: string
  RIGHT__BA_COHORT_MANAGEMENT_STUDENT_COURSE_WITHDRAW__EDIT: string
  RIGHT__BA_COHORT_MANAGEMENT_STUDENT_IMPORT__EDIT: string
  RIGHT__BA_COHORT_MANAGEMENT_STUDENT_PROGRAMME_STATUS__EDIT: string
  RIGHT__BA_COHORT_MANAGEMENT__DISPLAY: string
  RIGHT__BA_COHORT_MANAGEMENT__EDIT: string
  RIGHT__BA_STUDENT_MANAGEMENT_CLASSROOM__DISPLAY: string
  RIGHT__BA_STUDENT_MANAGEMENT_CURRENT_TERM__DISPLAY: string
  RIGHT__BA_STUDENT_MANAGEMENT_CURRENT_TERM__EDIT: string
  RIGHT__BA_STUDENT_MANAGEMENT_EXTRA_COURSES_SCHEDULE__DISPLAY: string
  RIGHT__BA_STUDENT_MANAGEMENT_EXTRA_COURSES_SCHEDULE__EDIT: string
  RIGHT__BA_STUDENT_MANAGEMENT_INTERNSHIP__DISPLAY: string
  RIGHT__BA_STUDENT_MANAGEMENT_NEXT_TERM__DISPLAY: string
  RIGHT__BA_STUDENT_MANAGEMENT_NEXT_TERM__EDIT: string
  RIGHT__BA_STUDENT_MANAGEMENT_PAST_CLASSROOM__DISPLAY: string
  RIGHT__BA_STUDENT_MANAGEMENT_PAST_CLASSROOM__EDIT: string
  RIGHT__BA_STUDENT_MANAGEMENT_PAST_TERM__DISPLAY: string
  RIGHT__BA_STUDENT_MANAGEMENT_PAST_TERM__EDIT: string
  RIGHT__BA_STUDENT_MANAGEMENT_TRANSFER_IN_FROM_INTERNAL__DISPLAY: string
  RIGHT__BA_STUDENT_MANAGEMENT_TRANSFER_IN__EDIT: string
  RIGHT__BA_STUDENT_MANAGEMENT_TRANSFER_OUT__DISPLAY: string
  RIGHT__BA_STUDENT_MANAGEMENT_TRANSFER_OUT__EDIT: string
  RIGHT__BA_STUDENT_MANAGEMENT__DISPLAY: string
  RIGHT__BA_STUDENT_STATUS__EDIT: string
  RIGHT__BA_TERM_MANAGEMENT_ACADEMIC_CALENDAR__DISPLAY: string
  RIGHT__BA_TERM_MANAGEMENT__EDIT: string
  RIGHT__CERTIFICATE__CREATE: string
  RIGHT__CLASSROOM_CONTACT_STAFF__EDIT: string
  RIGHT__CLASSROOM_CONTACT_STUDENT__EDIT: string
  RIGHT__CLASSROOM_FACILITATOR__EDIT: string
  RIGHT__CLASSROOM_GLOBAL__DISPLAY: string
  RIGHT__CLASSROOM_LISTS_NEXT_TERM__EXPORT: string
  RIGHT__CLASSROOM_LISTS_RUNNING_TERM__EXPORT: string
  RIGHT__CLASSROOM_LOCAL__DISPLAY: string
  RIGHT__CLASSROOM_MANAGEMENT_CLASSROOM__EDIT: string
  RIGHT__CLASSROOM_MANAGEMENT_FACILITATOR__EDIT: string
  RIGHT__CLASSROOM_MANAGEMENT_FACULTY__EDIT: string
  RIGHT__CLASSROOM_NAME__EDIT: string
  RIGHT__CLASSROOM_STUDENT__CREATE: string
  RIGHT__CLASSROOM_STUDENT__EDIT: string
  RIGHT__CLASSROOM__DISPLAY: string
  RIGHT__CLASSROOM__EXPORT: string
  RIGHT__CLC_DETAIL__DISPLAY: string
  RIGHT__CLC_REPORTING_STATUS__EDIT: string
  RIGHT__CLC__CREATE: string
  RIGHT__CLC__DELETE: string
  RIGHT__CLC__DISPLAY: string
  RIGHT__CLC__EDIT: string
  RIGHT__COORDINATOR_CLC__EDIT: string
  RIGHT__COORDINATOR__CREATE: string
  RIGHT__COORDINATOR__DELETE: string
  RIGHT__COORDINATOR__DISPLAY: string
  RIGHT__COORDINATOR__EDIT: string
  RIGHT__COURSE_ACCEPT_OR_DECLINE_STUDENTS__EDIT: string
  RIGHT__COURSE_COORDINATOR_DOCUMENTS_DISPLAY: string
  RIGHT__COURSE_ONLINE_DOCUMENTS_DISPLAY: string
  RIGHT__COURSE_ONSITE_DOCUMENTS_DISPLAY: string
  RIGHT__COURSE_PREVIEW__DISPLAY: string
  RIGHT__COURSE_TASK_ONLINE__DISPLAY: string
  RIGHT__COURSE_TASK_ONSITE__DISPLAY: string
  RIGHT__COURSE_TERM_SWITCH__DISPLAY: string
  RIGHT__DATA_CLEAN_UP__DISPLAY: string
  RIGHT__DATA_CLEAN_UP__EDIT: string
  RIGHT__FACILITATOR_CLC__EDIT: string
  RIGHT__FACILITATOR_INACTIVE__EDIT: string
  RIGHT__FACILITATOR_ONLINE_FACULTY__CREATE: string
  RIGHT__FACILITATOR_ONSITE_FACILITATOR__CREATE: string
  RIGHT__FACILITATOR_QUALIFICATION__EDIT: string
  RIGHT__FACILITATOR__CREATE: string
  RIGHT__FACILITATOR__DELETE: string
  RIGHT__FACILITATOR__DISPLAY: string
  RIGHT__FACILITATOR__EDIT: string
  RIGHT__FACULTY_INACTIVE__EDIT: string
  RIGHT__GEL_APPLICANTS__DISPLAY: string
  RIGHT__GEL_APPLICANT_ADD_JWL_ID_CREATE: string
  RIGHT__GEL_APPLICANT__EDIT: string
  RIGHT__GEL_CERTIFICATE_SIGNEE__EDIT: string
  RIGHT__GEL_CERTIFICATE__CREATE: string
  RIGHT__GEL_CLASSROOM_FACILITATOR__EDIT: string
  RIGHT__GEL_CLASSROOM__CREATE: string
  RIGHT__GEL_CLASSROOM__EDIT: string
  RIGHT__GEL_CLASSROOM__EXPORT: string
  RIGHT__GEL_CLC__CREATE: string
  RIGHT__GEL_EOL__EDIT: string
  RIGHT__GEL_PENDING_APPLICANTS__DISPLAY: string
  RIGHT__GEL_PENDING_APPLICANTS__EDIT: string
  RIGHT__GEL_PENDING_APPLICANT_PASSWORD_RESET__EDIT: string
  RIGHT__GEL_PREPENDING_APPLICANTS__DISPLAY: string
  RIGHT__GEL_STUDENT_ENTRY__DELETE: string
  RIGHT__GEL_STUDENT_EOL_ACTIVE__EDIT: string
  RIGHT__GEL_STUDENT_PROGRAMME_STATUS_WITHDRAW__EDIT: string
  RIGHT__GEL_STUDENT__MOVE: string
  RIGHT__GEL_STUDY_HISTORY__EDIT: string
  RIGHT__GEL_TERM_MANAGEMENT__DISPLAY: string
  RIGHT__GEL_TERM_MANAGEMENT__EDIT: string
  RIGHT__GEL_TERM_START_DATE__EDIT: string
  RIGHT__GEL_TERM__EDIT: string
  RIGHT__GEL_VALIDATE_PENDING_MINOR_APPLICANTS__EDIT: string
  RIGHT__GENERAL_LMS__DISPLAY: string
  RIGHT__GENERAL_SIS__DISPLAY: string
  RIGHT__GENERAL_UNIVERSITY__DISPLAY: string
  RIGHT__GRADE_BOOK_EXPORT_EXTENDED__DISPLAY: string
  RIGHT__GRADE_BOOK_FACILITATOR_DETAILS__DISPLAY: string
  RIGHT__GRADE_BOOK__DISPLAY: string
  RIGHT__GRADE_BOOK__EXPORT: string
  RIGHT__GRADE_CHANGE_REQUEST__CREATE: string
  RIGHT__GRADE_CHANGE_REQUEST__DISPLAY: string
  RIGHT__GRADE_FINAL_EXAM__DISPLAY: string
  RIGHT__HOME_ACTIVE_COURSES__DISPLAY: string
  RIGHT__HOME_GRADER__DISPLAY: string
  RIGHT__LEAD_FACILITATOR__CREATE: string
  RIGHT__LEAD_FACILITATOR__DISPLAY: string
  RIGHT__LEAD_FACILITATOR__EDIT: string
  RIGHT__LEAD_FACULTY__CREATE: string
  RIGHT__LEAD_FACULTY__DISPLAY: string
  RIGHT__LEAD_FACULTY__EDIT: string
  RIGHT__PASSWORD_RESET__EDIT: string
  RIGHT__PC_APPLICANT_ADD_JWL_ID_CREATE: string
  RIGHT__PC_CLASSROOM_MANAGEMENT__DISPLAY: string
  RIGHT__PC_FINISH_PROGRAMME_STUDENT_STATUS__EDIT: string
  RIGHT__PC_STUDENT_STATUS__EDIT: string
  RIGHT__PREVIEW_QUIZ__DISPLAY: string
  RIGHT__PREVIEW_SEE_ANSWERS__DISPLAY: string
  RIGHT__PROGRAMME_ACADEMIC__DISPLAY: string
  RIGHT__PROGRAMME_ADD_BREAK__EDIT: string
  RIGHT__PROGRAMME_APPLICANT__CREATE: string
  RIGHT__PROGRAMME_APPLICANT__EXPORT: string
  RIGHT__PROGRAMME_APPLICANT__IMPORT: string
  RIGHT__PROGRAMME_GEL__DISPLAY: string
  RIGHT__PROGRAMME_LINGUA_SKILL_EXCEL__UPLOAD: string
  RIGHT__PROGRAMME_PROFESSIONAL__DISPLAY: string
  RIGHT__PROGRAMME_REVIEW_CERTIFICATE_EXPORT__EDIT: string
  RIGHT__PROGRAMME_REVIEW_GRADES_EXPORT__EDIT: string
  RIGHT__PROGRAMME_REVIEW_GRADES_PASS_FAIL__EDIT: string
  RIGHT__PROGRAMME_REVIEW_GRADES__DISPLAY: string
  RIGHT__PROGRAMME__CLOSE: string
  RIGHT__PROGRAMME__CREATE: string
  RIGHT__PROGRAMME__DISPLAY: string
  RIGHT__PROGRAMME__EDIT: string
  RIGHT__QUIZ_SESSION_DETAIL__DISPLAY: string
  RIGHT__QUIZ_SESSION_UNRESTRICTED__EDIT: string
  RIGHT__QUIZ_SESSION__CREATE: string
  RIGHT__QUIZ_SESSION__DELETE: string
  RIGHT__QUIZ_SESSION__DISPLAY: string
  RIGHT__QUIZ_SESSION__EDIT: string
  RIGHT__REPORTING__DISPLAY: string
  RIGHT__RIGHT__CREATE: string
  RIGHT__RIGHT__DELETE: string
  RIGHT__RIGHT__DISPLAY: string
  RIGHT__RIGHT__EDIT: string
  RIGHT__ROLE_TO_RIGHT__EXPORT: string
  RIGHT__ROLE__CREATE: string
  RIGHT__ROLE__DELETE: string
  RIGHT__ROLE__DISPLAY: string
  RIGHT__ROLE__EDIT: string
  RIGHT__SIS_PROGRAMME_GEL__DISPLAY: string
  RIGHT__STUDENT_CLC_CHANGE__EDIT: string
  RIGHT__STUDENT_EXCEPT_MINORS__EDIT: string
  RIGHT__STUDENT_MINORS__EDIT: string
  RIGHT__STUDENT_PROFIL_PICTURE_SECOND_UPLOAD__EDIT: string
  RIGHT__STUDENT_PROGRAMME_STATUS__EDIT: string
  RIGHT__STUDENT_STUDY_HISTORY__DISPLAY: string
  RIGHT__STUDENT_WITH_ENROLMENT__EDIT: string
  RIGHT__STUDENT__ADVANCED_EDIT: string
  RIGHT__STUDENT__CREATE: string
  RIGHT__STUDENT__DISPLAY: string
  RIGHT__STUDENT__EDIT: string
  RIGHT__STUDENT__WITHDRAW: string
  RIGHT__SUBMISSION_CLOSED_BY__DISPLAY: string
  RIGHT__SUBMISSION_CLOSE_IN_UNIT_FOR_ONE_STUDENT__EDIT: string
  RIGHT__SUBMISSION_CLOSE_IN_UNIT__EDIT: string
  RIGHT__SUBMISSION_GRADE_PUBLISH__EDIT: string
  RIGHT__SUBMISSION_GRADE_SAVE__EDIT: string
  RIGHT__SUBMISSION_IN_DISCUSSION__CREATE: string
  RIGHT__SUBMISSION_ON_BEHALF__CREATE: string
  RIGHT__SUBMISSION_TURNITIN_REPORT_ADMIN__DISPLAY: string
  RIGHT__SUBMISSION_TURNITIN_REPORT_INSTRUCTOR__DISPLAY: string
  RIGHT__SUBMISSION_TURNITIN_REPORT_LEARNER__DISPLAY: string
  RIGHT__SUBMISSION__CREATE: string
  RIGHT__SUBMISSION__DISPLAY: string
  RIGHT__SUPPORT_SHOW_ENROLLMENTS__DISPLAY: string
  RIGHT__SYLLABUS_OF_ALL_COURSES__DISPLAY: string
  RIGHT__SYLLABUS_OF_ASSIGNED_COURSES__DISPLAY: string
  RIGHT__UNIT_CLOSE_FOR_SUBMISSION_FOR_ALL_STUDENTS__EDIT: string
  RIGHT__UNIT_CLOSE_FOR_SUBMISSION_FOR_ONE_STUDENT__EDIT: string
  RIGHT__UNIT_PUBLISH_FOR_SUBMISSION_FOR_ALL_STUDENTS__EDIT: string
  RIGHT__USER__CREATE: string
  RIGHT__USER__DELETE: string
  RIGHT__USER__DISPLAY: string
  RIGHT__USER__EDIT: string
  RIGHT__USER__IMPERSONATE: string
  RIGHT__WBT_ALL__DISPLAY: string
}
